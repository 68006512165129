(function ($) {
    let $fn = $(".comp_products_slider");

    if ($fn.length) {
        let $slider = $fn.find("[data-slider]");

        if($slider.children().length > 3){
            $slider.slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                prevArrow: "<i class=\"icon icon--chevron-left\" data-arrow=\"prev\"></i>",
                nextArrow: "<i class=\"icon icon--chevron-right\" data-arrow=\"next\"></i>",
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });
        }
    }
})(jQuery);