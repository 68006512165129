(function ($) {
    let $fn = $(".comp_contact");

    if($fn.length) {
        let $map = $fn.find("[data-map]");
        
        if($map.length){
            $.getScript($cdnjs.googlemap).done(function () {
                $map.nl_google_map('init');
                $map.nl_google_map('markers_add');
            });
        }
    }
})(jQuery);